hr {
	border: solid 1px #3b60b5;
}

ul {
	padding:0.3em 0.1em;
	list-style-type: none;
}

a {
	text-decoration: none;
}

button {
	background-color: #5a8be8;
	border: none;
	color: white;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 0.75em;
	cursor: pointer;
	margin-top: 5px;
}

.no-games {
	text-align: center;
	padding: 2em 0;

}

.header {
	background-color: #478caf;
	color: white;
	padding: 0.5em;
	border-bottom: 1px solid #0a1f4e;
}

.title {
	font-size: 1.2em;
}

.club-info {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 0.5em;
	background-color: #3b60b5;
	color: #e6e6e6;
}

.club-info .club-name {
	font-size: 1.2em;
	margin-right: 1em;
}

.club-actions {
	display: flex;
	flex-direction: row;
	padding: 0 0.5em;
	flex-grow: 1;
	justify-content: flex-end;
}

.call--button {
	padding: 5px 15px;
	margin-left: 0.5em;
}

.club-odd {
	background-color: #eeeeee;
}

.club-even {
	background-color: #dbdbdb;
}

.game-frame {
	display: flex;
	flex-direction: row;
	margin-top: 0.5em;
	justify-content: center;
}

.game-data {
	flex-direction: row;
	width: 100%;
}

.game-data-header {
	font-weight: bold;
	font-size: 1.2em;
	padding: 0;
}

.game-data-secondary {
	font-weight: bold;
	font-size: 1.0em;
	padding: 0.5em 0;
}

.game-data-comments {
	font-size: 0.7em;
}

@media (max-width: 500px) {
	.game-data {
		font-size: 0.8em;
	}
}

@media (max-width: 325px) {
	.game-data {
		font-size: 0.7em;
	}
}

.game-data ul {
	min-width: 140px;
	flex-grow: 1;
	padding: 0;
}

.players-grid {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
}

.player-slot {
	display: flex;
	flex-direction: row;
	width: 150px;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	border: solid 1px #3b4c8d;
	padding: 2px;
	margin: 2px;
}

.player-slot-free {
	display: flex;
	flex-direction: row;
	width: 150px;
	justify-content: center;
	align-items: center;
	height: 50px;
	border: solid 1px #3b4c8d;
	padding: 2px;
	margin: 2px;
}

.player-data {
	font-size: 0.7em;
	padding-left: 2px;
}

.player-name {
	width: 110px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.join-button {
	display: flex;
	justify-content: space-around;
	font-size: 1.5em;
	font-weight: normal;
	cursor: pointer;
}

.player-join-icon {
	padding: 7px 10px 0 0;
}

.player-image {
	padding: 1px 3px;
}