.games-view-container {
	min-height: 200px;
}

.grid-item button {
	width: 100%;
	border-radius: 0;
}

.games-view-title {
	padding: 0.5em;
}

.games-view-grid-container {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto auto;
}
