.game-filter--container {
	padding-top: 1em;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 80px 60px 60px auto;
}

.input-date-range {
	display: flex;
	flex-wrap: wrap;
	padding: 1em 0;
}

.input--container {
	padding: 0.5em 0;
}

.input--container .react-datepicker-wrapper {
	font-size: .7em;
}

.datepicker-container {
	display: flex;
	flex-direction: row;
}

.datepicker-container button {
	padding: 0 !important;
}

.react-datepicker__input-container > input {
	width: 250px;
}

.form-input {
	display: inline-block;
	min-width: 10em;
}

.react-datepicker__time-container {
	min-width: 150px;
}

.react-datepicker__time .react-datepicker__time-box {
	width: 100% !important;
}

.react-datepicker__time-list-item {
	font-size: 2em;
	line-height: 1.5em;
}

input {
	font-size: 1.5em;
}

.grid-container {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 25px auto auto;
}

@media (max-width: 400px) {
	.grid-item button {
		width: 100%;
		display: block;
		padding: 1em;
	}
}

@media (min-width: 401px) {
	.grid-item button {
		width: 250px;
		display: block;
		padding: 1em;
	}
}

.search-button {
	padding: 1em 0;
}
.club-item {
	text-decoration: none;
	cursor: pointer;
}

.club-item :hover {
	background-color: #3b60b5;
}

.club-item-avatar {
	margin-right: 1em;
	cursor: pointer;
}
.clubs-view--container {
    padding: 0 1em;
}

.add-club--action {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
hr {
	border: solid 1px #3b60b5;
}

ul {
	padding:0.3em 0.1em;
	list-style-type: none;
}

a {
	text-decoration: none;
}

button {
	background-color: #5a8be8;
	border: none;
	color: white;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 0.75em;
	cursor: pointer;
	margin-top: 5px;
}

.no-games {
	text-align: center;
	padding: 2em 0;

}

.header {
	background-color: #478caf;
	color: white;
	padding: 0.5em;
	border-bottom: 1px solid #0a1f4e;
}

.title {
	font-size: 1.2em;
}

.club-info {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 0.5em;
	background-color: #3b60b5;
	color: #e6e6e6;
}

.club-info .club-name {
	font-size: 1.2em;
	margin-right: 1em;
}

.club-actions {
	display: flex;
	flex-direction: row;
	padding: 0 0.5em;
	flex-grow: 1;
	justify-content: flex-end;
}

.call--button {
	padding: 5px 15px;
	margin-left: 0.5em;
}

.club-odd {
	background-color: #eeeeee;
}

.club-even {
	background-color: #dbdbdb;
}

.game-frame {
	display: flex;
	flex-direction: row;
	margin-top: 0.5em;
	justify-content: center;
}

.game-data {
	flex-direction: row;
	width: 100%;
}

.game-data-header {
	font-weight: bold;
	font-size: 1.2em;
	padding: 0;
}

.game-data-secondary {
	font-weight: bold;
	font-size: 1.0em;
	padding: 0.5em 0;
}

.game-data-comments {
	font-size: 0.7em;
}

@media (max-width: 500px) {
	.game-data {
		font-size: 0.8em;
	}
}

@media (max-width: 325px) {
	.game-data {
		font-size: 0.7em;
	}
}

.game-data ul {
	min-width: 140px;
	flex-grow: 1;
	padding: 0;
}

.players-grid {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
}

.player-slot {
	display: flex;
	flex-direction: row;
	width: 150px;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	border: solid 1px #3b4c8d;
	padding: 2px;
	margin: 2px;
}

.player-slot-free {
	display: flex;
	flex-direction: row;
	width: 150px;
	justify-content: center;
	align-items: center;
	height: 50px;
	border: solid 1px #3b4c8d;
	padding: 2px;
	margin: 2px;
}

.player-data {
	font-size: 0.7em;
	padding-left: 2px;
}

.player-name {
	width: 110px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.join-button {
	display: flex;
	justify-content: space-around;
	font-size: 1.5em;
	font-weight: normal;
	cursor: pointer;
}

.player-join-icon {
	padding: 7px 10px 0 0;
}

.player-image {
	padding: 1px 3px;
}
.games-view-container {
	min-height: 200px;
}

.grid-item button {
	width: 100%;
	border-radius: 0;
}

.games-view-title {
	padding: 0.5em;
}

.games-view-grid-container {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  text-decoration: none;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}

button {
  border-radius: 0;
}

.root-container {
  padding: 1em;
}

.menuButton {
  margin-left: -12px;
  margin-right: 20px;
}

.menu-title {
  padding: 1em;
  text-align: center;
}

.header-separator {
  flex: 1 1;
}

.menu-logo {
  flex-grow: 1;
  text-align: center;
  padding: 1em;
}

.menu-logo img {
  width: 60px;
}

.menu-sponsor  {
  flex-grow: 1;
  text-align: center;
  padding: 0.5em 0px;
  background-color: white;
}

.menu-sponsor-image {
  width: 100%;
}

.list {
  width: 250px;
  background-color: #3b60b5;
}

.about-view--container {
    padding: 1em;
}
.about-view--container {
    padding: 1em;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

