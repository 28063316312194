body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  text-decoration: none;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}

button {
  border-radius: 0;
}

.root-container {
  padding: 1em;
}

.menuButton {
  margin-left: -12px;
  margin-right: 20px;
}

.menu-title {
  padding: 1em;
  text-align: center;
}

.header-separator {
  flex: 1;
}

.menu-logo {
  flex-grow: 1;
  text-align: center;
  padding: 1em;
}

.menu-logo img {
  width: 60px;
}

.menu-sponsor  {
  flex-grow: 1;
  text-align: center;
  padding: 0.5em 0px;
  background-color: white;
}

.menu-sponsor-image {
  width: 100%;
}

.list {
  width: 250px;
  background-color: #3b60b5;
}
