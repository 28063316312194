.club-item {
	text-decoration: none;
	cursor: pointer;
}

.club-item :hover {
	background-color: #3b60b5;
}

.club-item-avatar {
	margin-right: 1em;
	cursor: pointer;
}