.game-filter--container {
	padding-top: 1em;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 80px 60px 60px auto;
}

.input-date-range {
	display: flex;
	flex-wrap: wrap;
	padding: 1em 0;
}

.input--container {
	padding: 0.5em 0;
}

.input--container .react-datepicker-wrapper {
	font-size: .7em;
}

.datepicker-container {
	display: flex;
	flex-direction: row;
}

.datepicker-container button {
	padding: 0 !important;
}

.react-datepicker__input-container > input {
	width: 250px;
}

.form-input {
	display: inline-block;
	min-width: 10em;
}

.react-datepicker__time-container {
	min-width: 150px;
}

.react-datepicker__time .react-datepicker__time-box {
	width: 100% !important;
}

.react-datepicker__time-list-item {
	font-size: 2em;
	line-height: 1.5em;
}
