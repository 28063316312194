input {
	font-size: 1.5em;
}

.grid-container {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 25px auto auto;
}

@media (max-width: 400px) {
	.grid-item button {
		width: 100%;
		display: block;
		padding: 1em;
	}
}

@media (min-width: 401px) {
	.grid-item button {
		width: 250px;
		display: block;
		padding: 1em;
	}
}

.search-button {
	padding: 1em 0;
}